<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="loadingProfile"
      variant="light"
      opacity="0.5"
      :blur="'true'"
      rounded="sm"
    >
      <b-card
        :title="`ข้อมูลสมาชิก`"
        v-if="memberForm"
      >
        <b-row>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              เบอร์โทร
            </h5>
            <cleave
              id="member-phoneno"
              v-model="memberForm.phoneno"
              class="form-control"
              :raw="false"
              :options="{
                numericOnly: true,
                blocks: [3, 3, 4],
                delimiters: ['-', '-', '-']
              }"
              placeholder="0XX-XXX-XXXX"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              ชื่อ
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="memberForm.fname"
              placeholder="ชื่อ"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              นามสกุล
            </h5>
            <b-form-input
              id="mc-last-name"
              v-model="memberForm.lname"
              placeholder="นามสกุล"
            />
          </b-col>
          <b-col md="3">
            <h5 class="text-capitalize mb-75">
              Line ID
            </h5>
            <b-form-input
              id="mc-line-id"
              v-model="memberForm.lineid"
              placeholder="ไลน์ไอดี"
            />
          </b-col>
          <b-col md="3" class="mt-10">
            <h5 class="text-capitalize mb-75">
              ช่องทางการสมัคร
            </h5>
            <v-select
              label="text"
              :options="subtype"
              v-model="memberForm.subscription"
              placeholder="ช่องทาง"
              :reduce="option => option.value" />
          </b-col>
          <b-col md="6" class="mt-10">
            <h5 class="text-capitalize mb-75">
              บัญชีธนาคาร
            </h5>
            <b-row>
              <b-col md="5">
                <v-select
                  v-model="memberForm.bank_code"
                  label="text"
                  :options="bankListOptions"
                  :reduce="bankItem => bankItem.value"
                >
                  <template #option="{ text, value }">
                    <img :src="getBankImage(value)" style="width: 24px;">
                    <span> {{ text }}</span>
                  </template>
                </v-select>
              </b-col>
              <b-col md="7">
                <cleave
                  id="member-bank-acc"
                  v-model="memberForm.bank_acc_no"
                  class="form-control"
                  :raw="false"
                  :options="{
                    numericOnly: true, numeralThousandsGroupStyle: 'none', numeral: true, numeralDecimalScale: 0, stripLeadingZeroes: false
                  }"
                  placeholder="เลขที่บัญชี"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col md="3" class="mt-10">
            <h5 class="text-capitalize mb-75">
              รหัสผ่าน
            </h5>
            <b-form-input
              id="mc-first-name"
              v-model="memberForm.password"
              placeholder="รหัสผ่าน"
            />
          </b-col>
          <b-col md="3" class="mt-10">
            <h5 class="text-capitalize mb-75">
              รับโปร
            </h5>
            <v-select
              v-model="memberForm.get_promo"
              label="text"
              :options="[{ text: 'ไม่รับ 50%', value: 'N' }, { text: 'รับโปร 50%', value: 'Y' }, ]"
              :reduce="proOption => proOption.value"
            />
          </b-col>
          <b-col md="9" class="mt-10">
            <br/>
          </b-col>
          <b-col md="3" class="mt-10">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              @click="saveProfile(memberForm, 'loadingProfile')"
            >
              บันทึก
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BFormInput, BButton, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    // BTable,
    // BBadge,
    // BFormGroup,
    // BFormSelect,
    // BPagination,
    // BCardText,
    // BInputGroup,
    BFormInput,
    // BInputGroupAppend,
    BButton,
    // BCardBody,
    BRow,
    BCol,
    // BForm,
    BOverlay,
    Cleave,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loadingProfile: false,
      userInput: null,
      isBusy: false,
      localVariables: {},
      pageOptions: [100, 200, 500, 1000],
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
      txtypes: [{ title: 'ทั้งหมด', value: '' }, { title: 'รายการฝาก', value: 'DEPOSIT' }, { title: 'รายการแจ้งถอน', value: 'WITHDRAW' }, { title: 'รายการคืนเงิน', value: 'REFUND' }, { title: 'โปรโมชั่น', value: 'PROMOTION' }, { title: 'วางเดิมพัน', value: 'BET' }, { title: 'เคลียร์บิล', value: 'BETWIN' }, { title: 'เดิมพันที่ถูกยกเลิก', value: 'BETCANCEL' }],
      platformOpts: [{ title: 'ทั้งหมด', value: '' }, { title: 'กระเป๋าเงิน', value: 'WALLET' }, { title: 'SA', value: 'SA' }, { title: 'SEXY', value: 'AE' }, { title: 'WM', value: 'WM' }, { title: 'DG', value: 'DG' }, { title: 'CQ9', value: 'CQ9' }, { title: 'JOKER', value: 'JOKER' }, { title: 'JDB', value: 'JDB' }, { title: 'MG', value: 'MG' }, { title: 'SBO', value: 'SBO' }, { title: 'HOTGRAPH', value: 'HOTGRAPH' }, { title: 'LOTTO', value: 'LOTTO' }],
      subtype: [
        { text: 'Facebook', value: 'Facebook' },
        { text: 'Line', value: 'Line' },
        { text: 'โฆษณา', value: 'โฆษณา' },
        { text: 'คนแนะนำ', value: 'คนแนะนำ' },
        { text: 'Google', value: 'Google' },
      ],
      totalWalletRows: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      tableQuery: {
        page: 1,
        page_size: 100,
      },
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      memberInfo: null,
      memberForm: {
        password: 'Aa112233',
        cfpassword: 'Aa112233',
        get_promo: 'N',
      },
      /* eslint-disable global-require */
    }
  },
  computed: {
  },
  methods: {
    async saveProfile(changes, loadingRef) {
      this[loadingRef] = true
      const changeMade = { ...changes }
      try {
        changeMade.phoneno = changeMade.phoneno.replace(/-/ig, '')
        if ((changeMade.lineid || '').length === 0) {
          changeMade.lineid = changeMade.phoneno
        }
        const { data: saveProfileResult } = await this.$http.post('users', { ...changeMade, cfpassword: changeMade.password })
        console.log(saveProfileResult)
        if (saveProfileResult.success) {
          //
          this.$bvToast.toast(`สมาชิก ${changeMade.phoneno} รหัสผ่าน ${changeMade.password}`, {
            variant: 'success',
            title: 'เพิ่มสมาชิกสำเร็จ',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: false,
          })
          this.$router.replace(`/members/${saveProfileResult.user.phoneno}`)
          return
        }
        throw new Error(saveProfileResult.error_message || '')
      } catch (err) {
        console.log(err)
      }
      this[loadingRef] = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}
</style>
